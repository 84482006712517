// components/Modal.tsx
import React, { useEffect, useState } from 'react';
import styles from './style.module.scss'; // Import CSS module for modal styles
import WsStaticImage from '../WsStaticImage';

interface WsModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  headerClass?: any;
  header?: React.ReactNode; // Add header prop
  children?: React.ReactNode;
  footer?: React.ReactNode; // Add footer prop.
  size?: 'small' | 'large' | 'x-large' | 'full' | 'auto'; // Add size prop
  position?: 'center' | 'bottom' | ''; // Add position prop
  bodyWidth?: number;
  noPadding?: boolean; // Add noPadding prop
  noClose?: boolean; // Add noPadding prop
  mainClass?: string;
  customMainClass?: string;
  zIndex?: number; // Add zIndex prop
}
const WsModal: React.FC<WsModalProps> = ({
  isOpen,
  onClose,
  title,
  header,
  children,
  footer,
  size = 'large',
  position = '',
  headerClass = [],
  bodyWidth,
  noPadding = false,
  noClose = false,
  mainClass = '',
  customMainClass = '',
  zIndex = 1000, // Default zIndex
}) => {
  const [visible, setVisible] = useState(isOpen);
  useEffect(() => {
    if (isOpen) {
      setVisible(true);
      document.body.style.overflow = 'hidden'; // Disable scrolling when modal is open
      // document.body.style.paddingRight ='17px';
    } else {
      setVisible(false);
      // setTimeout(() => setVisible(false), 300); // Match the transition duration
      document.body.style.overflow = ''; // Re-enable scrolling when modal is closed
      document.body.style.paddingRight = '0';
    }
    return () => {
      document.body.style.overflow = ''; // Re-enable scrolling when component unmounts
      document.body.style.paddingRight = '0';
    };
  }, [isOpen]);

  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  let customModalClass = '';
  if (headerClass.length) {
    headerClass.forEach((classItem: string) => {
      customModalClass += ' ' + styles[classItem];
    });
  }

  return visible ? (
    <div
      className={`${styles.modalBackdrop} ${isOpen ? styles.open : ''}`}
      style={{ zIndex: zIndex }}
      onClick={handleBackdropClick}
    >
      <div className={`${styles.modalcontent}`}></div>
      <div
        className={`${styles.modal} ${styles[size]} ${noPadding ? styles.noPadding : ''} ${position ? styles[position] : ''} ${mainClass && mainClass !== '' ? styles[mainClass] : ''} ${customMainClass} ${isOpen ? styles.open : ''}`}
        style={bodyWidth ? { width: bodyWidth } : {}}
      >
        {header ? (
          <div className={`${styles.modalHeader} ${customModalClass}`}>
            {header}
            {noClose == false && (
              <button className={styles.modalCloseBtn} onClick={onClose}>
                <WsStaticImage
                  src={'/images/category/modal-close-img.svg'}
                  alt="modal-close-img"
                  width={16}
                  height={16}
                />
              </button>
            )}
          </div>
        ) : (
          <div className={`${styles.modalHeader} ${customModalClass}`}>
            {title && <h2 className={styles.modalTitle}>{title}</h2>}
            {noClose == false && (
              <button className={styles.modalCloseBtn} onClick={onClose}>
                <WsStaticImage
                  src={'/images/category/modal-close-img.svg'}
                  alt="modal-close-img"
                  width={16}
                  height={16}
                />
              </button>
            )}
          </div>
        )}
        <div className={styles.modalBody}>{children}</div>
        {footer && <div className={styles.modalFooter}>{footer}</div>}
      </div>
    </div>
  ) : null;
};

export default WsModal;
